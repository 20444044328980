











































import { Component, Vue } from "vue-property-decorator";
import { IAmount } from "@/core/types";

@Component
export default class BaseAmount extends Vue {
  get type(): "sell" | "buy" {
    return this.$route.path.includes("vender") ? "sell" : "buy";
  }
  get forexInfoRate(): IAmount {
    return this.$store.state[this.type].prices.forexInfo.rate;
  }
  get feeNinetyNine(): IAmount {
    return this.$store.state[this.type].prices.forexInfo.rateWithCommission;
  }
}
